import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import MoneyField from '~/Components/Form/MoneyField';
import { CARTAO, MASK_DATE } from '~/constant';

import { default as Selector } from 'react-select';
import GeradorData from '~/Components/GeradorData';
import moment from 'moment';

export default function Cartao({ cartoes }) {
  const { values, setFieldValue, initialValues } = useFormikContext();
  if (values.tipo_parcela !== CARTAO) return null;

  const cartaoSelecionado = cartoes.find(
    item => item.codigo === values[CARTAO].cartao
  );

  const obterDiasCartao = value => {
    return cartoes.find(item => item.codigo === value).dias;
  };

  return (
    <>
      <Row>
        <Col md="8">
          <Form.Group>
            <Form.Label>Cartão</Form.Label>
            <Selector
              name={`${CARTAO}.cartao`}
              noOptionsMessage={() => 'Cartão não encontrado'}
              placeholder="Selecione a forma de pagamento..."
              defaultValue={
                cartaoSelecionado
                  ? {
                      label: cartaoSelecionado.descricao,
                      value: cartaoSelecionado.codigo,
                    }
                  : { label: 'Selecione', value: '' }
              }
              options={cartoes.map(({ codigo, descricao }) => ({
                value: codigo,
                label: descricao,
              }))}
              onChange={option => {
                setFieldValue(`${CARTAO}.cartao`, option.value);
                setFieldValue(
                  `${CARTAO}.vencimento_mask`,
                  moment()
                    .add(obterDiasCartao(option.value), 'days')
                    .format('DD/MM/YYYY')
                );
              }}
            />
            <ErrorMessage
              name={`${CARTAO}.cartao`}
              component="small"
              className="text-danger"
            />
          </Form.Group>
        </Col>
        {!initialValues.hasOwnProperty('index') && (
          <Col md="4">
            <Form.Group>
              <Form.Label>Qtd. Parcelas</Form.Label>
              <Field
                name={`${CARTAO}.qtdParcelas`}
                className="form-control"
                type="tel"
                placeholder="Ex.: 10"
              />
              <ErrorMessage
                name={`${CARTAO}.qtdParcelas`}
                component="small"
                className="text-danger"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col md="4">
          <Form.Group>
            <Form.Label>Valor da parcela</Form.Label>
            <Field
              name={`${CARTAO}.valor`}
              className="form-control"
              type="tel"
              placeholder="Ex.: 1.000,00"
              component={MoneyField}
            />
            <ErrorMessage
              name={`${CARTAO}.valor`}
              component="small"
              className="text-danger"
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <Form.Label>Vencimento</Form.Label>
            <Field
              name={`${CARTAO}.vencimento_mask`}
              as={MaskedInput}
              mask={MASK_DATE}
              className="form-control"
              type="tel"
              placeholder="Ex.: 10/10/2020"
              // disabled={cartaoSelecionado && diasCartao.dias == 0}
            />
            <ErrorMessage
              name={`${CARTAO}.vencimento_mask`}
              className="text-danger"
              component="small"
            />
            <GeradorData
              original={values[CARTAO].vencimento_mask}
              onUpdate={date => setFieldValue(`${CARTAO}.vencimento`, date)}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <Form.Label>N° Documento</Form.Label>
            <Field
              name={`${CARTAO}.numero_documento`}
              className="form-control"
              type="tel"
              placeholder="Ex.: 123456"
              maxLength={12}
            />
            <ErrorMessage
              name={`${CARTAO}.numero_documento`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
