import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as api from '~/Services/Service';
import { selecionarSocio } from '~/Store/Prospecto/actions';
import { FaPen, FaTrash } from 'react-icons/fa';
import ModalConjugeSocio from './ConjugeSocio/ModalConjugeSocio';
import ProspectoFormatacaoDados from './ProspectoFormatacaoDados';
import { fetchProspectoDetalhes } from '~/Store/Prospecto/actions';
import { toast } from 'react-toastify';
import AuthStorage from '~/Utils/AuthStorage';
import { caracteristicas } from '~/constant';

class ListaSocios extends Component {
  state = {
    isShowModal: false,
    socioEditando: null,
  };

  excluirSocio = async socio => {
    try {
      await api.excluirSocio(socio);
      this.props.fetchProspectoDetalhes(socio.codigo_prospecto_cliente);
      toast.success('Cônjuge/Sócio remvoido com sucesso.');
    } catch (error) {
      toast.error(error.message);
    }
  };

  setShowModal = isShowModal => {
    this.setState({ isShowModal, socioEditando: null });
  };

  setSocioEditar = socioEditando => {
    this.setState({ isShowModal: true, socioEditando });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.exibirMensagemSucessoSalvarSocio &&
      !prevProps.exibirMensagemSucessoSalvarSocio
    ) {
      this.setShowModal(false);
    }
  }

  render() {
    const {
      ConjugePorEmpreendimento,
      socioSelecionado,
      selecionarSocio,
      fetchProspectoDetalhes,
      exibirMensagemSucessoSalvarSocio,
    } = this.props;

    const { isShowModal, socioEditando } = this.state;

    return (
      <>
        {exibirMensagemSucessoSalvarSocio && (
          <Alert variant="success">Dados atualizados com sucesso</Alert>
        )}
        <div className="text-right d-flex d-md-inline-flex mb-2">
          <button
            className="btn w-100 d-sm-inline-block btn-sm btn-outline-primary"
            onClick={() => this.setShowModal(true)}
          >
            Adicionar Cônjuge ou Sócio
          </button>
        </div>

        <ModalConjugeSocio
          showModal={isShowModal || !!socioEditando}
          socio={socioEditando}
          onClose={() => this.setShowModal(false)}
        />

        <ListGroup>
          {ConjugePorEmpreendimento.map((socio, index) => {
            return (
              <ListGroupItem key={index}>
                <div className="d-flex w-100 justify-content-between">
                  <h5
                    onClick={() => selecionarSocio(socio.codigo)}
                    className={`btn btn-link btn-sm mb-0 ${socioSelecionado ===
                      socio.codigo && 'text-primary'}`}
                  >
                    {socio.nome}
                  </h5>
                  <div>
                    <button
                      className="btn btn-link btn-sm"
                      onClick={() => this.setSocioEditar(socio.codigo)}
                      title="Editar"
                    >
                      <FaPen />
                    </button>
                    {AuthStorage.temCaracteristica(
                      caracteristicas.ExclusaoSocioConjuge
                    ) && (
                      <button
                        className="btn btn-sm"
                        onClick={() => {
                          this.excluirSocio(socio);
                        }}
                        title="Excluir"
                      >
                        <FaTrash color="red" />
                      </button>
                    )}
                  </div>
                </div>
                {socioSelecionado === socio.codigo && (
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <ul className="list-unstyled line-height-1">
                        <ProspectoFormatacaoDados
                          prospecto={socio}
                          isDadosPessoais={true}
                        >
                          {({ values }) =>
                            values.map((item, index) => (
                              <li key={index} className="mb-2">
                                <small className="d-block">{item.label}</small>
                                <strong className="d-block">
                                  {item.value}
                                </strong>
                              </li>
                            ))
                          }
                        </ProspectoFormatacaoDados>
                      </ul>
                    </div>
                    <div className="col-12 col-md-6">
                      <ul className="list-unstyled line-height-1">
                        <ProspectoFormatacaoDados
                          prospecto={socio}
                          isDadosPessoais={false}
                        >
                          {({ values }) =>
                            values.map((item, index) => (
                              <li key={index} className="mb-2">
                                <small className="d-block">{item.label}</small>
                                <strong className="d-block">
                                  {item.value}
                                </strong>
                              </li>
                            ))
                          }
                        </ProspectoFormatacaoDados>
                      </ul>
                    </div>
                  </div>
                )}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </>
    );
  }
}

const mapStateToProps = state => ({
  socioSelecionado: state.prospecto.socioSelecionado,
  ConjugePorEmpreendimento: state.prospecto.detail.ConjugePorEmpreendimento,
  exibirMensagemSucessoSalvarSocio:
    state.prospecto.exibirMensagemSucessoSalvarSocio,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProspectoDetalhes,
      selecionarSocio,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListaSocios)
);
