import React, { Fragment } from 'react';
import * as constants from '../../../../constant';
import MaskedInput from 'react-text-mask';
import * as helpers from '../../../../Utils/Helpers';
import { Col, Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { default as Selector } from 'react-select';
import ConsultaCPF from '../ConsultaCPF';
import DateMask from '../../../DateMask';
import BuscarProfissao from './BuscarProfissao';

const FormDadosPessoais = ({
  isSubmitting,
  isRequiredField,
  values,
  options,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  touched,
}) => {
  const filtroPDV = string => {
    return options.pdv
      .filter(pdv => pdv.descricao.toLowerCase().includes(string.toLowerCase()))
      .map(item => ({
        value: item.codigo,
        label: item.descricao,
      }));
  };

  let timeout = null;
  const carregarPDV = (inputValue, callback) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(filtroPDV(inputValue));
    }, 500);
  };

  const isSocio = values && values.codigo_prospecto_cliente;
  const required = fieldName =>
    isRequiredField(fieldName) && <sup className="text-danger">*</sup>;
  const isPessoaFisica = parseInt(values.tipo_pessoa) === 0;
  const isPerfilExterior = parseInt(values.codigo_perfil_comprador) === 4;

  return (
    <div className="row">
      <Col xs="12" md="6">
        {values.codigo && !isSocio && (
          <Form.Group controlId="values.codigo">
            <Form.Label>Código Prospecto</Form.Label>
            <Form.Control
              type="text"
              name="codigo"
              value={values.codigo}
              disabled={true}
            />
          </Form.Group>
        )}
        {values.codigo_prospecto_cliente && (
          <Form.Group controlId="values.tipo_conjuge_socio">
            <Form.Label>
              Tipo do Sócio/Cônjuge {required('tipo_conjuge_socio')}
            </Form.Label>
            <Form.Control
              as="select"
              name="tipo_conjuge_socio"
              onChange={handleChange}
              value={values.tipo_conjuge_socio}
              onBlur={handleBlur}
              isInvalid={errors.tipo_conjuge_socio}
              disabled={isSubmitting}
            >
              <option value="">Selecione um tipo</option>
              {constants.ESTADO_CIVIL_CS.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.tipo}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group controlId="values.tipo_pessoa">
          <Form.Label>Tipo do Cadastro {required('tipo_pessoa')}</Form.Label>
          <Form.Control
            as="select"
            name="tipo_pessoa"
            onChange={handleChange}
            value={values.tipo_pessoa}
            onBlur={handleBlur}
            isInvalid={errors.tipo_pessoa && touched.tipo_pessoa}
            disabled={isSubmitting}
          >
            {constants.TIPO_PESSOA.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.tipo_pessoa}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="values.nome">
          <Form.Label>Nome {required('nome')}</Form.Label>
          <Form.Control
            type="text"
            name="nome"
            maxLength="100"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.nome}
            isInvalid={errors.nome && touched.nome}
            placeholder="Nome do cliente"
            disabled={isSubmitting}
          />
          <Form.Control.Feedback type="invalid">
            {errors.nome}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="values.nome_social">
          <Form.Label>Nome social {required('nome_social')}</Form.Label>
          <Form.Control
            type="text"
            name="nome_social"
            maxLength="60"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.nome_social}
            placeholder="Nome social do cliente"
            disabled={isSubmitting}
          />
        </Form.Group>
        <Form.Group controlId="values.email">
          <Form.Label>Email {required('email')}</Form.Label>
          <Form.Control
            type="text"
            name="email"
            maxLength="60"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            isInvalid={errors.email && touched.email}
            placeholder="email@dominio.com.br"
            disabled={isSubmitting}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="values.tel_celular">
          <Form.Label>Celular {required('tel_celular')}</Form.Label>
          <MaskedInput
            type="cel"
            className={`form-control ${errors.tel_celular &&
              touched.tel_celular &&
              'is-invalid'}`}
            name="tel_celular"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.tel_celular}
            mask={helpers.mask.celular}
            placeholder="(00) 00000-0000"
            disabled={isSubmitting}
          />
          <Form.Control.Feedback type="invalid">
            {errors.tel_celular}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="values.tel_residencial">
          <Form.Label>Telefone residencial</Form.Label>
          <MaskedInput
            type="cel"
            className={`form-control ${errors.tel_residencial &&
              touched.tel_residencial &&
              'is-invalid'}`}
            name="tel_residencial"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.tel_residencial}
            mask={helpers.mask.celular}
            placeholder="(00) 0000-0000"
            disabled={isSubmitting}
          />
          <Form.Control.Feedback type="invalid">
            {errors.tel_residencial}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="values.tel_comercial">
          <Form.Label>Telefone comercial</Form.Label>
          <MaskedInput
            type="tel"
            className={`form-control ${errors.tel_comercial &&
              touched.tel_comercial &&
              'is-invalid'}`}
            name="tel_comercial"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.tel_comercial}
            mask={helpers.mask.celular}
            placeholder="(00) 0000-0000"
            disabled={isSubmitting}
          />
          <Form.Control.Feedback type="invalid">
            {errors.tel_comercial}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="values.cpf">
          <Form.Label>{isPessoaFisica ? 'CPF' : 'CNPJ'}</Form.Label>
          <ConsultaCPF
            onChangeCPF={handleChange}
            ignore={!!isSocio}
            values={values}
          >
            {({ onChangeCPF, consultandoCPF }) => (
              <Fragment>
                <MaskedInput
                  type="tel"
                  name="cpf"
                  className={`form-control ${errors.cpf &&
                    touched.cpf &&
                    'is-invalid'}`}
                  onChange={onChangeCPF}
                  onBlur={handleBlur}
                  value={helpers.format.cpf(values.cpf)}
                  placeholder={
                    isPessoaFisica
                      ? 'Ex.: 000.000.000-00'
                      : 'Ex.: 00.000.000/0000-00'
                  }
                  mask={
                    isPessoaFisica ? constants.MASK_CPF : constants.MASK_CNPJ
                  }
                  disabled={
                    isSubmitting ||
                    consultandoCPF ||
                    !!values.codigo_cliente_sua_house
                  }
                />
              </Fragment>
            )}
          </ConsultaCPF>
          {errors.cpf && (
            <small className="form-text text-danger">{errors.cpf}</small>
          )}
        </Form.Group>
        {isPessoaFisica && (
          <Form.Group controlId="values.estado_civil">
            <Form.Label>Estado Civil</Form.Label>
            <Form.Control
              as="select"
              name="estado_civil"
              onChange={handleChange}
              value={values.estado_civil}
              onBlur={handleBlur}
              isInvalid={errors.estado_civil && touched.estado_civil}
              disabled={isSubmitting}
            >
              <option value="">Selecione o Estado Civil</option>
              {constants.ESTADO_CIVIL_CLIENTE.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.estado_civil}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group controlId="values.sexo">
          <Form.Label>Gênero</Form.Label>
          <Form.Control
            as="select"
            name="sexo"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.sexo}
            disabled={isSubmitting}
            isInvalid={errors.sexo && touched.sexo}
          >
            <option value="">Selecione o gênero</option>
            {constants.SEXO.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.sexo}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="12" md="6">
        <Form.Group controlId="values.codigo_perfil_comprador">
          <Form.Label>
            Perfil comprador {required('codigo_perfil_comprador')}
          </Form.Label>
          <Form.Control
            as="select"
            name="codigo_perfil_comprador"
            onChange={handleChange}
            value={values.codigo_perfil_comprador}
            onBlur={handleBlur}
            isInvalid={
              errors.codigo_perfil_comprador && touched.codigo_perfil_comprador
            }
            disabled={isSubmitting}
          >
            <option value="">Selecione um perfil...</option>
            {constants.PERFIL_COMPRADOR.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.codigo_perfil_comprador}
          </Form.Control.Feedback>
        </Form.Group>
        {isPerfilExterior && (
          <>
            <Form.Group controlId="values.perfil_exterior_ddi">
              <Form.Label>DDI Exterior</Form.Label>
              <Form.Control
                type="text"
                name="perfil_exterior_ddi"
                maxLength="4"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.perfil_exterior_ddi}
                placeholder="+351"
                disabled={isSubmitting}
                isInvalid={
                  errors.perfil_exterior_ddi && touched.perfil_exterior_ddi
                }
              />
            </Form.Group>
            <Form.Group controlId="values.perfil_exterior_telefone">
              <Form.Label>Telefone Exterior</Form.Label>
              <Form.Control
                type="text"
                name="perfil_exterior_telefone"
                maxLength="15"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.perfil_exterior_telefone}
                placeholder="000 000 000"
                disabled={isSubmitting}
                isInvalid={
                  errors.perfil_exterior_telefone &&
                  touched.perfil_exterior_telefone
                }
              />
            </Form.Group>
            <Form.Group controlId="values.perfil_exterior_pais">
              <Form.Label>Pais Exterior</Form.Label>
              <Form.Control
                type="text"
                name="perfil_exterior_pais"
                maxLength="50"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.perfil_exterior_pais}
                placeholder="Irlanda"
                disabled={isSubmitting}
                isInvalid={
                  errors.perfil_exterior_pais && touched.perfil_exterior_pais
                }
              />
            </Form.Group>
            <Form.Group controlId="values.perfil_exterior_endereco">
              <Form.Label>Endereço Exterior</Form.Label>
              <Form.Control
                type="text"
                name="perfil_exterior_endereco"
                maxLength="100"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.perfil_exterior_endereco}
                placeholder="Dublin"
                disabled={isSubmitting}
                isInvalid={
                  errors.perfil_exterior_endereco &&
                  touched.perfil_exterior_endereco
                }
              />
            </Form.Group>
          </>
        )}
        <DateMask
          initialValue={values.nascimento}
          onValidate={value => setFieldValue('nascimento', value)}
          restrictToSqlMinDate
          allowPast
        >
          {({ dateError, dateValue, setDate }) => (
            <Form.Group controlId="values.nascimento">
              <Form.Label>
                {isPessoaFisica ? 'Data de nascimento' : 'Data abertura'}
              </Form.Label>
              <MaskedInput
                name="nascimento"
                type="tel"
                className={`form-control ${((errors.nascimento &&
                  touched.nascimento) ||
                  dateError) &&
                  'is-invalid'}`}
                placeholder="Ex.: 29/12/1990"
                mask={constants.MASK_DATE}
                value={dateValue}
                onChange={e => setDate(e.target.value)}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nascimento}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {dateError}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </DateMask>
        {!isSocio && isPessoaFisica && (
          <Form.Group controlId="values.codigo_pdv">
            <Form.Label>PDV</Form.Label>
            <AsyncSelect
              id="pdf"
              isDisabled={options.pdv.length === 0 || isSubmitting}
              isLoading={options.pdv.length === 0}
              loadingMessage={() => 'Carregando...'}
              noOptionsMessage={() => 'Digite um PDV. Ex.: Revo Brasil'}
              loadOptions={carregarPDV}
              onChange={option => {
                setFieldValue('codigo_pdv', option.value);
              }}
              value={
                values.codigo_pdv && options.pdv.length > 0
                  ? {
                      value: parseInt(
                        options.pdvById[values.codigo_pdv].codigo
                      ),
                      label: options.pdvById[values.codigo_pdv].descricao,
                    }
                  : {
                      value: '',
                      label:
                        options.pdv.length === 0
                          ? 'Carregando...'
                          : 'Selecione um PDV',
                    }
              }
            />
            {errors.pdv && (
              <small className="form-text text-danger">{errors.pdv}</small>
            )}
          </Form.Group>
        )}
        {isPessoaFisica && (
          <Form.Group controlId="values.codigo_nacionalidade">
            <Form.Label>Nacionalidade</Form.Label>
            <Selector
              inputId="nacionalidade"
              isDisabled={options.nacionalidades.length === 0 || isSubmitting}
              isLoading={options.nacionalidades.length === 0}
              loadingMessage={() => 'Carregando...'}
              noOptionsMessage={() =>
                'Digite uma nacionalidade. Ex.: Brasileiro'
              }
              placeholder="Pesquisar nacionalidade..."
              options={options.nacionalidades.map(item => ({
                value: item.codigo,
                label: item.descricao,
              }))}
              onChange={option => {
                setFieldValue('codigo_nacionalidade', option.value);
              }}
              value={
                values.codigo_nacionalidade && options.nacionalidades.length > 0
                  ? {
                      value: parseInt(
                        options.nacionalidadesById[values.codigo_nacionalidade]
                          .codigo
                      ),
                      label:
                        options.nacionalidadesById[values.codigo_nacionalidade]
                          .descricao,
                    }
                  : {
                      value: '',
                      label:
                        options.nacionalidades.length === 0
                          ? 'Carregando...'
                          : 'Selecione uma Nacionalidade',
                    }
              }
            />
            {errors.codigo_nacionalidade && (
              <small className="form-text text-danger">
                {errors.codigo_nacionalidade}
              </small>
            )}
          </Form.Group>
        )}
        <Form.Group controlId="values.codigo_profissao">
          <Form.Label>Profissão</Form.Label>
          <BuscarProfissao>
            {({ carregarProfissoes }) => (
              <AsyncSelect
                inputId="profissao"
                isDisabled={isSubmitting}
                loadingMessage={() => 'Carregando...'}
                noOptionsMessage={() =>
                  'Digite uma profissão. Ex.: Comerciante'
                }
                placeholder="Pesquisar profissão..."
                loadOptions={carregarProfissoes}
                defaultOptions={[]}
                styles={{
                  control: provided => {
                    if (errors && errors.codigo_profissao) {
                      return { ...provided, borderColor: '#dc3545' };
                    }
                    return provided;
                  },
                }}
                onChange={option => {
                  setFieldValue('codigo_profissao', option.value);
                  setFieldValue('descricao_profissao', option.label);
                }}
                value={
                  values.codigo_profissao
                    ? {
                        value: parseInt(values.codigo_profissao),
                        label: values.descricao_profissao,
                      }
                    : { value: '', label: 'Selecione uma profissão' }
                }
              />
            )}
          </BuscarProfissao>
          {errors.codigo_profissao && (
            <small className="form-text text-danger">
              {errors.codigo_profissao}
            </small>
          )}
        </Form.Group>
        <Form.Group controlId="values.rg">
          <Form.Label>{isPessoaFisica ? 'RG' : 'IE'}</Form.Label>
          <Form.Control
            type="text"
            name="rg"
            maxLength="25"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.rg}
            placeholder={isPessoaFisica ? 'Ex.: 00.000.000-X' : 'Ex.: ISENTO'}
            disabled={isSubmitting}
            isInvalid={errors.rg && touched.rg}
          />
          <Form.Control.Feedback type="invalid">
            {errors.rg}
          </Form.Control.Feedback>
        </Form.Group>
        {isPessoaFisica && (
          <Form.Group controlId="values.orgao_emissor_rg">
            <Form.Label>Orgão Emissor RG</Form.Label>
            <Form.Control
              type="text"
              name="orgao_emissor_rg"
              maxLength="25"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgao_emissor_rg}
              placeholder="Ex.: SSP/SP"
              disabled={isSubmitting}
              isInvalid={errors.orgao_emissor_rg && touched.orgao_emissor_rg}
            />
            <Form.Control.Feedback type="invalid">
              {errors.orgao_emissor_rg}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        {isPessoaFisica && (
          <DateMask
            initialValue={values.data_emissao_rg}
            onValidate={value => setFieldValue('data_emissao_rg', value)}
            restrictToSqlMinDate
          >
            {({ dateError, dateValue, setDate }) => (
              <Form.Group controlId="values.data_emissao_rg">
                <Form.Label>Data Emissão RG</Form.Label>
                <MaskedInput
                  type="tel"
                  name="data_emissao_rg"
                  className={`form-control ${((errors.data_emissao_rg &&
                    touched.data_emissao_rg) ||
                    dateError) &&
                    'is-invalid'}`}
                  onChange={e => setDate(e.target.value)}
                  onBlur={handleBlur}
                  value={dateValue}
                  mask={constants.MASK_DATE}
                  placeholder="Ex.: 30/08/2000"
                  disabled={isSubmitting}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.data_emissao_rg}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {dateError}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </DateMask>
        )}
        {isPessoaFisica && (
          <Form.Group controlId="values.cnh">
            <Form.Label>CNH</Form.Label>
            <Form.Control
              type="text"
              name="cnh"
              maxLength="20"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cnh}
              placeholder="Ex.: 0123456789"
              disabled={isSubmitting}
              isInvalid={errors.cnh && touched.cnh}
            />
            <Form.Control.Feedback type="invalid">
              {errors.cnh}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        {isPessoaFisica && (
          <Form.Group controlId="prospecto.orgao_emissor_cnh">
            <Form.Label>Orgão Emissor CNH</Form.Label>
            <Form.Control
              as="select"
              className="form-control"
              name="orgao_emissor_cnh"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgao_emissor_cnh}
              disabled={isSubmitting}
              isInvalid={errors.orgao_emissor_cnh && touched.orgao_emissor_cnh}
            >
              <option value="">Selecione Orgão Emissor</option>
              {constants.ORGAO_EMISSOR_CNH.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.orgao_emissor_cnh}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        {isSocio && (
          <Form.Group controlId="values.tabeliao">
            <Form.Label>Tabelião</Form.Label>
            <Form.Control
              type="text"
              name="tabeliao"
              maxLength="50"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.tabeliao}
              placeholder="Ex.: 1º Cartório de Notas - São José do Rio Preto"
              disabled={isSubmitting}
              isInvalid={errors.tabeliao && touched.tabeliao}
            />
            <Form.Control.Feedback type="invalid">
              {errors.tabeliao}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        {isSocio && (
          <DateMask
            initialValue={values.data_casamento}
            onValidate={value => setFieldValue('data_casamento', value)}
          >
            {({ dateError, dateValue, setDate }) => (
              <Form.Group controlId="values.data_casamento">
                <Form.Label>Data Casamento</Form.Label>
                <MaskedInput
                  name="data_casamento"
                  type="tel"
                  className={`form-control ${((errors.data_casamento &&
                    touched.data_casamento) ||
                    dateError) &&
                    'is-invalid'}`}
                  placeholder="Ex.: 29/12/1990"
                  mask={constants.MASK_DATE}
                  value={dateValue}
                  onChange={e => setDate(e.target.value)}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.data_casamento}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {dateError}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </DateMask>
        )}
        {isSocio && (
          <Form.Group controlId="values.registro_antenupcial">
            <Form.Label>Registro Antenupcial</Form.Label>
            <Form.Control
              type="text"
              name="registro_antenupcial"
              maxLength="20"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.registro_antenupcial}
              placeholder="Ex.: Registro Pacto Antenupcial"
              disabled={isSubmitting}
              isInvalid={
                errors.registro_antenupcial && touched.registro_antenupcial
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.registro_antenupcial}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </Col>
    </div>
  );
};

export default FormDadosPessoais;
