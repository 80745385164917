import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import _size from 'lodash/size';
import { FaSpinner } from 'react-icons/fa';

import socioSchemaValidacoes from './SocioSchemaValidacoes';
import isRequiredField from '~/Utils/isRequiredField';

import FormDadosPessoais from '../../ProspectoDetalhes/FormDadosPessoais';
import { salvarSocio, getAtualizarCidades } from '~/Store/Prospecto/actions';
import { nullPropertiesToString } from '~/Utils/Helpers';

const ModalAdicionarProspecto = ({
  permitidoAdicionarDocumento,
  options,
  salvarSocio,
  salvandoSocio,
  erroSalvarSocio,
  prospecto,
  onClose,
  showModal,
  socio = null,
}) => {
  let initialValues = {
    codigo_prospecto_cliente: prospecto.codigo,
    codigo_grupo_empresa: prospecto.codigo_grupo_empresa,
    codigo_conta: prospecto.codigo_conta,
    codigo_familia: prospecto.codigo_familia,
    codigo_corretor: prospecto.codigo_corretor,
    codigo_gerente: prospecto.codigo_gerente,
    codigo_nacionalidade: 1,
    tipo_pessoa: 0,
    tipo: '',
    nome: '',
    cpf: '',
    email: '',
    tel_celular: '',
    tel_residencial: '',
    tel_comercial: '',
    estado_civil: prospecto.estado_civil,
    sexo: '',
    rg: '',
    data_emissao_rg: '',
    cnh: '',
    orgao_emissor_cnh: '',
    nascimento: '',
    codigo_status: '',
    cep: prospecto.cep,
    estado: prospecto.estado,
    bairro: prospecto.bairro,
    endereco: prospecto.endereco,
    numero: prospecto.numero,
    complemento: prospecto.complemento,
    cidade: prospecto.cidade,
    codigo_cidade: prospecto.codigo_cidade,
    orgao_emissor_rg: '',
    data_casamento: '',
    registro_antenupcial: '',
    tabeliao: '',
  };

  if (socio) {
    let socioEditar = prospecto.ConjugePorEmpreendimento.find(
      item => item.codigo === socio
    );
    socioEditar = socioEditar || {};
    //Sobrescreve o objeto para renderizar o modal
    initialValues = { ...initialValues, ...socioEditar };

    initialValues = nullPropertiesToString(initialValues);
  }

  const salvarConjugeSocio = values => {
    salvarSocio(values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={salvarConjugeSocio}
      validationSchema={socioSchemaValidacoes}
    >
      {({ errors, touched, handleSubmit, submitCount, ...formikProps }) => {
        return (
          <Modal
            size="lg"
            show={showModal && permitidoAdicionarDocumento}
            onHide={onClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Novo Cônjuge/Sócio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormDadosPessoais
                permitidoAdicionarDocumento={permitidoAdicionarDocumento}
                formProspecto={{
                  ...formikProps,
                  errors,
                  touched,
                  handleSubmit,
                }}
                onCancel={onClose}
                isRequiredField={field =>
                  isRequiredField(field, socioSchemaValidacoes)
                }
                isSubmitting={salvandoSocio}
                getAtualizarCidades={getAtualizarCidades}
                options={options}
              />
              {submitCount > 0 && _size(errors) > 0 && (
                <div className="FormDadosPessoais__error text-danger">
                  Preenchas os campos corretamente antes de salvar
                </div>
              )}
              {erroSalvarSocio && (
                <div className="FormDadosPessoais__error text-danger">
                  Não foi possível salvar os dados do cônjuge/sócio. Confira os
                  valores inseridos, como nome, CPF/CNPJ ou outros campos
                  obrigatórios, e tente novamente.
                </div>
              )}
              <div className="d-flex d-sm-block text-right justify-content-between">
                <Button
                  variant="link"
                  className="text-uppercase px-4 d-block d-sm-inline-block"
                  onClick={onClose}
                  disabled={salvandoSocio}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleSubmit}
                  className="text-uppercase px-4 d-block d-sm-inline-block"
                  disabled={salvandoSocio}
                >
                  {salvandoSocio ? (
                    <>
                      <FaSpinner /> Salvando...
                    </>
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => ({
  permitidoAdicionarDocumento:
    state.prospecto.detail.permitidoAdicionarDocumento,
  options: state.options,
  salvandoSocio: state.prospecto.salvandoSocio,
  erroSalvarSocio: state.prospecto.erroSalvarSocio,
  prospecto: state.prospecto.detail,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      salvarSocio,
      getAtualizarCidades,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalAdicionarProspecto)
);
